const vimeoVideo =
  document.querySelector('.video a[href^="https://vimeo.com/"]') || null;
const blogVideo = document.querySelector(".post-header a.video") || null;

// Vimeo videos
if (vimeoVideo) {
  vimeoVideo.addEventListener("click", e => {
    e.preventDefault();
    const id = e.target.href.replace("https://vimeo.com/", "");
    const container = e.target.closest(".video");
    const controls = container.querySelector(".controls");
    const currentImage = getComputedStyle(container)["background-image"];
    container.style.backgroundImage = `linear-gradient(rgba(47,64,78,0.8), rgba(47,64,78,0.8)), ${currentImage}`;

    const newControls = document.createElement("div");
    newControls.classList.add("player");
    newControls.innerHTML = `
      <iframe
        src="https://player.vimeo.com/video/${id}?autoplay=1&title=0&byline=0&portrait=0"
        width="100%"
        height="auto"
        frameborder="0"
        webkitallowfullscreen
        mozallowfullscreen
        allowfullscreen
        autoplay>
      </iframe>
    `;
    controls.parentNode.replaceChild(newControls, controls);

    // Hacky scroll video into view
    container.querySelector(".player").scrollIntoView();
    const scrolledY = window.scrollY;
    if (scrolledY) {
      window.scroll(0, scrolledY - 76);
    }
  });
}

// Blog videos
if (blogVideo) {
  blogVideo.addEventListener("click", e => {
    e.preventDefault();
    const id = e.target.href.split("=")[1];
    if (!id) {
      return false;
    }
    const parent = e.target.parentNode;
    const height = parent.querySelector("img").height();
    parent.innerHTML = `
      <iframe
        src="https://www.youtube.com/embed/${id}?autoplay=1"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen
        style="height: ${height}px">
      </iframe>
    `;
  });
}
